import React from 'react';

import { Helmet } from 'react-helmet';

import Main from 'components/common/main';
import Header from 'components/common/header';
import { PageMeta } from 'constants/page_meta';
import Block1 from 'assets/images/pricing/block-1.png';
import Block2 from 'assets/images/pricing/block-2.png';
import Block3 from 'assets/images/pricing/pricing.jpg';
import Block4 from 'assets/images/pricing/block-4.png';
import styled from 'styled-components';
import theme from 'config/theme';
import { Divider } from 'lib/Divider';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import Footer from 'components/common/footer';
import { Calculator } from './Calculator';

const { colors } = theme;

const meta = PageMeta.getStatic('pricing');

interface ContentBlockType {
	heading: string;
	content: string;
	image: string;
	imageLeft?: boolean;
}

const contentBlocksData: ContentBlockType[] = [
	{
		heading: 'Listing is free',
		content:
			"It is completely free to list as many properties as you'd like - so you have nothing to lose by listing your property now!",
		image: Block1,
	},
	{
		heading: 'Potential value',
		content:
			'DigsConnect has connected 64 372 landlords and tenants to date, genrating tens of millions of rands in lease value for landlords. List your property now.',
		image: Block2,
		imageLeft: true,
	},
	{
		heading: 'Pay on success',
		content:
			'If you find a tenant on DigsConnect, your DigsConnect booking concierge will invoice you for the once-off DigsConnect success fee.',
		image: Block3,
	},
	{
		heading: 'Invoice and fee',
		content: `Upon confirmation of tenancy the landlord will be charged a fixed finder's fee of R850 for leases shorter than 6 months, and R1550 for leases of 6 months or longer per tenant. Rates are exclusive of VAT.`,
		image: Block4,
		imageLeft: true,
	},
];

export const Pricing = () => (
	<Main>
		<Helmet>
			<title>{meta.title}</title>
			<meta name="description" content={meta.desc} />
		</Helmet>
		<Header searchable />
		<PageContent>
			<Title>It's completely free to list on DigsConnect, you only pay if you find a tenant!</Title>
			{contentBlocksData.map((item, i) => (
				<ContentBlock alt={item.imageLeft} key={i}>
					<ContentSection>
						<ContentTitle>{item.heading}</ContentTitle>
						<ContentText>{item.content}</ContentText>
					</ContentSection>
					<ImageSection>
						<ContentImage src={item.image} />
					</ImageSection>
				</ContentBlock>
			))}
		</PageContent>
		<Footer />
	</Main>
);

export default Pricing;

const ContentBlock = styled.div<{ alt?: boolean }>`
	display: flex;
	flex-direction: ${({ alt }) => (alt ? 'row-reverse' : 'row')};

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		flex-direction: column-reverse;
		align-items: center;
	}
`;

const ContentImage = styled.img`
	max-width: 300px;
	max-height: 300px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		max-width: 100%;
		max-height: 100%;
		max-width: auto;
		max-height: auto;
	}
`;

const ContentTitle = styled.div`
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 16px;
`;

const ContentText = styled.p`
	font-size: 16px;
`;

const ContentSection = styled.div`
	max-width: 300px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1;
	margin: 48px;
	margin-top: 24px;
	margin-bottom: 24px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		max-width: 100%;
		max-height: 100%;
		margin: 16px;
	}
`;

const ImageSection = styled.div`
	height: 300px;
	width: 300px;
	background: ${colors.grey10};
	border-radius: 16px;
	overflow: hidden;
	margin: 48px;
	margin-top: 24px;
	margin-bottom: 24px;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		max-width: 300px;
		max-height: 300px;
		margin: 16px;
		height: auto;
		width: auto;
	}
`;

const PageContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 24px;
`;

const Title = styled.div`
	font-size: 20px;
	color: ${colors.darkBlue};
	font-weight: 600;
	max-width: 450px;
	text-align: center;
	margin: 16px;
	margin-top: 16px;
`;
